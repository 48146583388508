<template>
<div style="width:100%">
  <v-list-item class="pa-0 ma-0">
    <v-list-item-content class="pa-3 ma-0">
        <v-list-item-title>{{ item.original }} </v-list-item-title>
        <v-list-item-title>{{ rec_function_fecha_colombia(item.created_at) }} </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action>
      <v-list-item-action-text>{{ rec_funciones_bytesToSize(item.tamanio) }}</v-list-item-action-text>
      <v-btn @click="download(item)" icon><v-icon color="grey lighten-1">mdi-cloud-download</v-icon></v-btn>
    </v-list-item-action>
  </v-list-item>
</div>
</template>
<script>
import recFunciones from '@/js/rec_funciones.js'
import recUpload from '@/js/rec_upload.js'
export default {
  name: 'ver_archivosComponent',
  components: {
  },
  mixins: [recUpload, recFunciones],
  props: ['color', 'item', 'tipo'],
  data: () => ({

  }),
  methods: {
    download (item) {
      if (this.tipo === 'GESTION') {
        this.rec_upload_download(item.id, item.original)
      } else if (this.tipo === 'LOG') {
        this.rec_upload_log_download(item.id, item.original)
      } else if (this.tipo === 'ESCALAMIENTO') {
        this.rec_upload_escalamiento_download(item.id, item.original)
      } else if (this.tipo === 'CIERREFINAL') {
        this.rec_upload_cierrefinal_download(item.id, item.original)
      }
    }
  }
}
</script>
